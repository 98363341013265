<template>
  <div class="page_content page_content_performance">
    <div class="content_header d-flex align-items-center">
      <span>業績查詢</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="search_section flex-column align-items-start">
          <div class="d-flex">
            <div class="form-group d-flex align-items-center mb-2">
              <label for="system">系統</label>
              <select
                id="system"
                v-model="search.system"
                name="system"
                class="form-control search_brand"
                :class="{'is-invalid': errors.has('search.system')}"
                data-vv-scope="search"
                @change="get_main_category()"
              >
                <!-- <option value disabled selected hidden>系統</option> -->
                <option
                  :value="''"
                >
                  不限
                </option>
                <option
                  v-for="item in system_data"
                  :key="item.systemCode"
                  :value="item.systemCode"
                >
                  {{ item.systemName }}
                </option>
              </select>
            </div>
            <div
              v-if="search.mode === 'category'"
              class="form-group d-flex align-items-center mb-2"
            >
              <label for="main_category">主分類</label>
              <select
                id="main_category"
                v-model="search.main_category"
                name="main_category"
                class="form-control"
                :class="{'is-invalid': errors.has('search.main_category')}"
                data-vv-scope="search"
                @change="get_sub_category()"
              >
                <!-- <option value disabled selected hidden>主分類</option> -->
                <option
                  :value="''"
                >
                  不限
                </option>
                <option
                  v-for="item in category_data"
                  :key="item.categoryCode"
                  :value="item.categoryCode"
                >
                  {{ item.categoryName }}
                </option>
              </select>
            </div>
            <div
              v-if="search.mode === 'category'"
              class="form-group d-flex align-items-center mb-2"
            >
              <label for="sub_category">次分類</label>
              <select
                id="sub_category"
                v-model="search.sub_category"
                name="sub_category"
                class="form-control"
                :class="{'is-invalid': errors.has('search.sub_category')}"
                data-vv-scope="search"
              >
                <!-- <option value disabled selected hidden>次分類</option> -->
                <option
                  :value="''"
                >
                  不限
                </option>
                <option
                  v-for="item in subcategory_data"
                  :key="item.categorySubCode"
                  :value="item.categorySubCode"
                >
                  {{ item.categorySubName }}
                </option>
              </select>
            </div>
            <div
              v-if="search.mode === 'category'"
              class="form-check form-check-inline m-0"
            >
              <input
                id="search_optionGroupDate"
                v-model="search.optionGroupDate"
                class="form-check-input"
                type="checkbox"
                :true-value="true"
                :false-value="false"
              >
              <label
                class="form-check-label m-0"
                for="search_optionGroupDate"
              >日期分組</label>
            </div>
            <div
              v-if="search.mode === 'brand'"
              class="form-group d-flex align-items-center mb-2"
            >
              <label for="brand">品牌標籤</label>
              <select
                id="brand"
                v-model="search.value"
                v-validate="'required'"
                name="brand"
                class="form-control"
                :class="{'is-invalid': errors.has('search.brand')}"
                data-vv-scope="search"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  品牌標籤
                </option>
                <option
                  v-for="item in option.performanceProductBrand"
                  :key="item.labelSubCode"
                  :value="item.labelSubCode"
                >
                  {{ item.labelSubName }}
                </option>
              </select>
            </div>
            <div
              v-if="search.mode === 'group'"
              class="form-group d-flex align-items-center mb-2"
            >
              <label for="group">商品分眾</label>
              <select
                id="group"
                v-model="search.value"
                v-validate="'required'"
                name="group"
                class="form-control"
                :class="{'is-invalid': errors.has('search.group')}"
                data-vv-scope="search"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  商品分眾
                </option>
                <option
                  v-for="item in option.performanceProductGroup"
                  :key="item.labelSubCode"
                  :value="item.labelSubCode"
                >
                  {{ item.labelSubName }}
                </option>
              </select>
            </div>
            <div
              v-if="search.mode === 'salesEvent'"
              class="form-group d-flex align-items-center mb-2"
            >
              <label for="group">優惠活動</label>
              <select
                id="salesEvent"
                v-model="search.value"
                v-validate="'required'"
                name="salesEvent"
                class="form-control"
                :class="{'is-invalid': errors.has('search.salesEvent')}"
                data-vv-scope="search"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  優惠活動
                </option>
                <option
                  v-for="item in option.performanceSalesEvent"
                  :key="item.saleEventCode"
                  :value="item.saleEventCode"
                >
                  {{ item.saleEventName }}
                </option>
              </select>
            </div>
            <div
              v-if="search.mode === 'coupon'"
              class="form-group d-flex align-items-center mb-2"
            >
              <label for="coupon">兌換活動</label>
              <select
                id="coupon"
                v-model="search.value"
                v-validate="'required'"
                name="coupon"
                class="form-control"
                :class="{'is-invalid': errors.has('search.coupon')}"
                data-vv-scope="search"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  兌換活動
                </option>
                <option
                  v-for="item in option.performanceCoupon"
                  :key="item.couponSerial"
                  :value="item.couponSerial"
                >
                  {{ item.couponName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group d-flex align-items-baseline mb-2">
            <label>搜尋模式</label>
            <div>
              <div>
                <div class="form-check form-check-inline">
                  <input
                    id="search_mode_order"
                    v-model="search.mode"
                    class="form-check-input"
                    type="radio"
                    :value="'order'"
                    @change="switch_searchmode()"
                  >
                  <label
                    class="form-check-label"
                    for="search_mode_order"
                  >依訂單</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="search_mode_orderPerDay"
                    v-model="search.mode"
                    class="form-check-input"
                    type="radio"
                    :value="'orderPerDay'"
                    @change="switch_searchmode()"
                  >
                  <label
                    class="form-check-label"
                    for="search_mode_orderPerDay"
                  >依日期</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="search_mode_category"
                    v-model="search.mode"
                    class="form-check-input"
                    type="radio"
                    :value="'category'"
                    @change="switch_searchmode()"
                  >
                  <label
                    class="form-check-label"
                    for="search_mode_category"
                  >依分類</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="search_mode_brand"
                    v-model="search.mode"
                    class="form-check-input"
                    type="radio"
                    :value="'brand'"
                    @change="switch_searchmode()"
                  >
                  <label
                    class="form-check-label"
                    for="search_mode_brand"
                  >依品牌</label>
                </div>
              </div>
              <div>
                <div class="form-check form-check-inline">
                  <input
                    id="search_mode_group"
                    v-model="search.mode"
                    class="form-check-input"
                    type="radio"
                    :value="'group'"
                    @change="switch_searchmode()"
                  >
                  <label
                    class="form-check-label"
                    for="search_mode_group"
                  >依分眾</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="search_mode_keyword"
                    v-model="search.mode"
                    class="form-check-input"
                    type="radio"
                    :value="'keyword'"
                    @change="switch_searchmode()"
                  >
                  <label
                    class="form-check-label"
                    for="search_mode_keyword"
                  >依關鍵字</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="search_mode_salesEvent"
                    v-model="search.mode"
                    class="form-check-input"
                    type="radio"
                    :value="'salesEvent'"
                    @change="switch_searchmode()"
                  >
                  <label
                    class="form-check-label"
                    for="search_mode_salesEvent"
                  >依優惠活動</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="search_mode_coupon"
                    v-model="search.mode"
                    class="form-check-input"
                    type="radio"
                    :value="'coupon'"
                    @change="switch_searchmode()"
                  >
                  <label
                    class="form-check-label"
                    for="search_mode_coupon"
                  >依兌換活動</label>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="search.mode !== 'salesEvent' && search.mode !== 'coupon'"
            class="d-flex mb-2"
          >
            <div class="form-group d-flex align-items-center">
              <label for="saerch_dateStart">起始日期</label>
              <input
                id="saerch_dateStart"
                v-model="search.dateStart"
                v-validate="'required'"
                type="date"
                name="saerch_dateStart"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('search.saerch_dateStart')}"
                data-vv-scope="search"
                placeholder="選擇日期"
              >
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="saerch_dateEnd">最後日期</label>
              <input
                id="saerch_dateEnd"
                v-model="search.dateEnd"
                v-validate="'required'"
                type="date"
                name="saerch_dateEnd"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('search.saerch_dateEnd')}"
                data-vv-scope="search"
                placeholder="選擇日期"
              >
            </div>
          </div>
          <div
            v-if="search.mode === 'keyword'"
            class="form-group d-flex align-items-center mb-2"
          >
            <label for="search_keyword">關鍵字</label>
            <input
              id="search_keyword"
              v-model="search.value"
              v-validate="'required'"
              type="text"
              name="keyword"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.keyword')}"
              data-vv-scope="search"
              placeholder="輸入品號或品名"
            >
          </div>
          <a
            href="#"
            class="search_btn d-flex align-items-center justify-content-center"
            @click.prevent="check_performance(search.system, search.mode)"
          >
            <span>搜尋</span>
          </a>
        </div>
        <template v-if="performance_order.items.length !== 0">
          <div class="performance_info">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>總業績</span>
                  <span>{{ performance_order.totalAmount | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>訂單數量</span>
                  <span>{{ performance_order.orderCounter | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>已出貨訂單數</span>
                  <span>{{ performance_order.orderProcessed | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>客單價</span>
                  <span>{{ performance_order.orderAmountPct | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>客貢獻</span>
                  <span>{{ performance_order.orderAmountArpu | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>不重複客人數</span>
                  <span>{{ performance_order.orderNonRepetitive | currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="performance_orderPerDay.items.length !== 0">
          <div class="performance_info">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>總業績</span>
                  <span>{{ performance_orderPerDay.totalAmount | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>訂單數量</span>
                  <span>{{ performance_orderPerDay.orderCounter | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>已出貨訂單數</span>
                  <span>{{ performance_orderPerDay.orderProcessed | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>客單價</span>
                  <span>{{ performance_orderPerDay.orderAmountPct | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>客貢獻</span>
                  <span>{{ performance_orderPerDay.orderAmountArpu | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>不重複客人數</span>
                  <span>{{ performance_orderPerDay.orderNonRepetitive | currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="performance_default.items.length !== 0">
          <div class="performance_info">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-3">
                <div class="performance_info_item">
                  <span>總件數</span>
                  <span>{{ performance_default.totalQuantity | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="performance_info_item">
                  <span>總業績</span>
                  <span>{{ performance_default.totalAmount | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="performance_info_item">
                  <span>訂單數量</span>
                  <span>{{ performance_default.orderCounter | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="performance_info_item">
                  <span>已出貨訂單數</span>
                  <span>{{ performance_default.orderProcessed | currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="performance_event.items.length !== 0">
          <div class="performance_info">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>總業績</span>
                  <span>{{ performance_event.totalAmount | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>訂單數量</span>
                  <span>{{ performance_event.orderCounter | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>已出貨訂單數</span>
                  <span>{{ performance_event.orderProcessed | currency }}</span>
                </div>
              </div>
              <!-- <div class="col-12 col-md-4">
                <div class="performance_info_item">
                  <span>所選活動說明</span>
                  <span v-html="performance_event.salesEventDec"></span>
                </div>
              </div> -->
            </div>
          </div>
        </template>
        <template v-if="performance_coupon.items.length !== 0">
          <div class="performance_info">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-3">
                <div class="performance_info_item">
                  <span>總業績</span>
                  <span>{{ performance_coupon.totalAmount | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="performance_info_item">
                  <span>訂單數量</span>
                  <span>{{ performance_coupon.orderCounter | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="performance_info_item">
                  <span>已出貨訂單數</span>
                  <span>{{ performance_coupon.orderProcessed | currency }}</span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="performance_info_item">
                  <span>兌換人數</span>
                  <span>{{ performance_coupon.customerCounter | currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="table-responsive">
          <template v-if="performance_order.items.length !== 0">
            <table class="table table-borderless table_even">
              <thead class="thead-light">
                <tr>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購日期
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購編號
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購資料
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    付款方式
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    <span class="mr-1">金額</span>
                    <i
                      v-if="sort_amount_status"
                      class="icon-chevron-thin-down"
                      @click="sort_data(performance_order, 'amount')"
                    />
                    <i
                      v-else
                      class="icon-chevron-thin-up"
                      @click="sort_data(performance_order, 'amount')"
                    />
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    <span class="mr-1">件數/種類</span>
                    <i
                      v-if="sort_quantity_status"
                      class="icon-chevron-thin-down"
                      @click="sort_data(performance_order, 'quantity')"
                    />
                    <i
                      v-else
                      class="icon-chevron-thin-up"
                      @click="sort_data(performance_order, 'quantity')"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(order, index) in filter_Data"
                  :key="index"
                >
                  <td>
                    <div>{{ order.orderDate }}</div>
                  </td>
                  <td>
                    <div>{{ order.orderSerial }}</div>
                    <div>{{ order.receiptType }} {{ order.receiptNumber }}</div>
                  </td>
                  <td>
                    <div>{{ order.fullname }}</div>
                    <div>{{ order.celnum }}</div>
                    <div>{{ order.address }}</div>
                  </td>
                  <td>
                    <div v-html="order.paymentDesc" />
                  </td>
                  <td>
                    <div>{{ order.amount | currency }}</div>
                  </td>
                  <td>
                    <div>數量: {{ order.quantity | currency }}</div>
                    <div>種類: {{ order.counter | currency }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-if="performance_orderPerDay.items.length !== 0">
            <table class="table table-borderless table_odd">
              <thead class="thead-light">
                <tr>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購日期
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    <span class="mr-1">訂單數量</span>
                    <i
                      v-if="sort_quantity_status"
                      class="icon-chevron-thin-down"
                      @click="sort_data(performance_orderPerDay, 'counter')"
                    />
                    <i
                      v-else
                      class="icon-chevron-thin-up"
                      @click="sort_data(performance_orderPerDay, 'counter')"
                    />
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    不重複客人數
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    <span class="mr-1">金額</span>
                    <i
                      v-if="sort_amount_status"
                      class="icon-chevron-thin-down"
                      @click="sort_data(performance_orderPerDay, 'amount')"
                    />
                    <i
                      v-else
                      class="icon-chevron-thin-up"
                      @click="sort_data(performance_orderPerDay, 'amount')"
                    />
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    客單價/貢獻
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(order, index) in filter_Data"
                  :key="index"
                >
                  <td>
                    <div>{{ order.orderDate }}</div>
                  </td>
                  <td>
                    <div>{{ order.counter | currency }}</div>
                  </td>
                  <td>
                    <div>{{ order.nonRepetitive | currency }}</div>
                  </td>
                  <td>
                    <div>{{ order.amount | currency }}</div>
                  </td>
                  <td>
                    <div>客單價: {{ order.amountPct | currency }}</div>
                    <div>客貢獻: {{ order.amountArpu | currency }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-if="performance_default.items.length !== 0">
            <table class="table table-borderless table_default">
              <thead class="thead-light">
                <tr>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購日期
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    商品代碼
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    商品名稱
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    平均單價
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    <span class="mr-1">數量</span>
                    <i
                      v-if="sort_quantity_status"
                      class="icon-chevron-thin-down"
                      @click="sort_data(performance_default, 'quantity')"
                    />
                    <i
                      v-else
                      class="icon-chevron-thin-up"
                      @click="sort_data(performance_default, 'quantity')"
                    />
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    <span class="mr-1">小計</span>
                    <i
                      v-if="sort_amount_status"
                      class="icon-chevron-thin-down"
                      @click="sort_data(performance_default, 'amount')"
                    />
                    <i
                      v-else
                      class="icon-chevron-thin-up"
                      @click="sort_data(performance_default, 'amount')"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(performance, index) in filter_Data"
                  :key="index"
                >
                  <td>
                    <div>{{ performance.orderDate }}</div>
                  </td>
                  <td>
                    <div>{{ performance.prodSerial }}</div>
                  </td>
                  <td>
                    <div>{{ performance.prodName }}{{ performance.standard }}</div>
                  </td>
                  <td>
                    <div>{{ performance.price | currency }}</div>
                  </td>
                  <td>
                    <div>{{ performance.quantity | currency }}</div>
                  </td>
                  <td>
                    <div>{{ performance.amount | currency }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-if="performance_event.items.length !== 0">
            <table class="table table-borderless table_event">
              <thead class="thead-light">
                <tr>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購日期
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購編號
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購資料
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    到貨日
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    <span class="mr-1">金額</span>
                    <i
                      v-if="sort_amount_status"
                      class="icon-chevron-thin-down"
                      @click="sort_data(performance_event, 'amount')"
                    />
                    <i
                      v-else
                      class="icon-chevron-thin-up"
                      @click="sort_data(performance_event, 'amount')"
                    />
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    活動數量/金額
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(performance, index) in filter_Data"
                  :key="index"
                >
                  <td>
                    <div>{{ performance.orderDate }}</div>
                  </td>
                  <td>
                    <div>{{ performance.orderSerial }}</div>
                  </td>
                  <td>
                    <div>{{ performance.fullname }}</div>
                    <div>{{ performance.celnum }}</div>
                    <div>{{ performance.address }}</div>
                  </td>
                  <td>
                    <div>{{ performance.arriveDate }}</div>
                  </td>
                  <td>
                    <div>{{ performance.amount | currency }}</div>
                  </td>
                  <td>
                    <div>{{ performance.discountQuantity | currency }}</div>
                    <div>{{ performance.discountAmount }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-if="performance_coupon.items.length !== 0">
            <table class="table table-borderless table_coupon">
              <thead class="thead-light">
                <tr>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購日期
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購編號
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    訂購資料
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    到貨日
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    <span class="mr-1">金額</span>
                    <i
                      v-if="sort_amount_status"
                      class="icon-chevron-thin-down"
                      @click="sort_data(performance_coupon, 'amount')"
                    />
                    <i
                      v-else
                      class="icon-chevron-thin-up"
                      @click="sort_data(performance_coupon, 'amount')"
                    />
                  </th>
                  <th
                    scope="col"
                    class="text-color_primary"
                  >
                    兌換碼/折扣金額
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(performance, index) in filter_Data"
                  :key="index"
                >
                  <td>
                    <div>{{ performance.orderDate }}</div>
                  </td>
                  <td>
                    <div>{{ performance.orderSerial }}</div>
                  </td>
                  <td>
                    <div>{{ performance.fullname }}</div>
                    <div>{{ performance.celnum }}</div>
                    <div>{{ performance.address }}</div>
                  </td>
                  <td>
                    <div>{{ performance.arriveDate }}</div>
                  </td>
                  <td>
                    <div>{{ performance.amount | currency }}</div>
                  </td>
                  <td>
                    <div>{{ performance.couponCode }}</div>
                    <div>{{ performance.discountAmount }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
        <ul
          v-if="filter_Data.length > 0"
          class="pagination"
        >
          <li
            class="pagination-item"
            :class="{ 'pagination-item-disabled': !pagination.has_pre }"
          >
            <a
              href="#"
              class="pagination-link"
              aria-label="Previous"
              @click.prevent="change_page(pagination.current_page - 1)"
            >
              <i class="icon-chevron-thin-left" />
            </a>
          </li>
          <li
            v-for="(page, index) in pagination.filter_range_page"
            :key="index"
            class="pagination-item"
          >
            <a
              href="#"
              class="pagination-link"
              :class="{ 'pagination-link-active': pagination.current_page === page.index }"
              @click.prevent="change_page(page.index)"
            >{{ page.index }}</a>
          </li>
          <li
            v-if="pagination.total_pages > 10"
            class="pagination-item"
          >
            <span>...</span>
          </li>
          <li
            class="pagination-item"
            :class="{ 'pagination-item-disabled': !pagination.has_next }"
          >
            <a
              href="#"
              class="pagination-link"
              aria-label="Next"
              @click.prevent="change_page(pagination.current_page + 1)"
            >
              <i class="icon-chevron-thin-right" />
            </a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_PERFORMANCE_ORDER,
  API_GET_PERFORMANCE_ORDERPERDAY,
  API_GET_PERFORMANCE_CATEGORY,
  API_GET_PERFORMANCE_BRAND,
  API_GET_PERFORMANCE_GROUP,
  API_GET_PERFORMANCE_KEYWORD,
  API_GET_PERFORMANCE_SALESEVENT,
  API_GET_PERFORMANCE_COUPON,
  API_GET_PRODUCT_CATEGORY,
} from '@/plugins/api';

export default {
  data() {
    return {
      now_page: 'performance',
      search: {
        system: '',
        mode: '',
        dateStart: '',
        dateEnd: '',
        main_category: '',
        sub_category: '',
        value: '',
        optionGroupDate: true,
      },
      category_data: [] /* 主次分類資料 */,
      subcategory_data: [] /* 次分類列表 */,
      performance_order: { /* 業績資料 */
        items: [],
      },
      performance_orderPerDay: { /* 業績資料 */
        items: [],
      },
      performance_default: { /* 業績資料 */
        items: [],
      },
      performance_event: { /* 業績資料 */
        items: [],
      },
      performance_coupon: { /* 業績資料 */
        items: [],
      },
      sort_amount_status: true, /* 金額排序狀態 true: 小到大;false: 大到小 */
      sort_quantity_status: true, /* 數量排序狀態 true: 小到大;false: 大到小 */
      pagination: {
        /* 分頁設定 */
        filter_page_size: 10,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      },
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option']),
    system_data: {
      get() {
        const vm = this;
        const data = vm.system.slice();
        return data;
      },
    },
    /* 訂單資料分頁化 */
    filter_Data() {
      const vm = this;
      let performanceData = [];
      if (vm.performance_order.items.length !== 0) {
        performanceData = vm.performance_order.items;
      }
      if (vm.performance_orderPerDay.items.length !== 0) {
        performanceData = vm.performance_orderPerDay.items;
      }
      if (vm.performance_default.items.length !== 0) {
        performanceData = vm.performance_default.items;
      }
      if (vm.performance_event.items.length !== 0) {
        performanceData = vm.performance_event.items;
      }
      if (vm.performance_coupon.items.length !== 0) {
        performanceData = vm.performance_coupon.items;
      }
      const dataLen = performanceData.length;
      const nowPage = vm.pagination.current_page;
      vm.pagination.total_pages = Math.ceil(
        dataLen / vm.pagination.filter_page_size,
      );
      vm.pagination.filter_range_page = [];
      for (let index = 1; index <= vm.pagination.total_pages; index += 1) {
        if (index >= (nowPage - vm.pagination.filter_range) && index <= (nowPage + vm.pagination.filter_range)) {
          vm.pagination.filter_range_page.push({
            index,
          });
        }
      }
      if (nowPage > 1) {
        vm.pagination.has_pre = true;
      } else {
        vm.pagination.has_pre = false;
      }
      if (nowPage === vm.pagination.total_pages) {
        vm.pagination.has_next = false;
      } else {
        vm.pagination.has_next = true;
      }
      return performanceData.filter(
        (item, index) => index < nowPage * vm.pagination.filter_page_size
          && index >= (nowPage - 1) * vm.pagination.filter_page_size,
      );
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_system_option();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system', 'get_system_option']),
    /* 業績資料初始化 */
    async init_performance_data() {
      const vm = this;
      vm.performance_order = { /* 業績資料 */
        items: [],
      };
      vm.performance_orderPerDay = { /* 業績資料 */
        items: [],
      };
      vm.performance_default = { /* 業績資料 */
        items: [],
      };
      vm.performance_event = { /* 業績資料 */
        items: [],
      };
      vm.performance_coupon = { /* 業績資料 */
        items: [],
      };
    },
    async init_pagination() {
      const vm = this;
      vm.pagination = {
        filter_page_size: 10,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      };
    },
    /* 切換頁數 */
    change_page(page = 1) {
      const vm = this;
      vm.pagination.current_page = page;
    },
    /* 商品分類資料初始化 */
    async init_category_data() {
      const vm = this;
      vm.search.main_category = '';
      vm.search.sub_category = '';
      vm.category_data = [];
      vm.subcategory_data = [];
    },
    /* 切換搜尋模式 */
    async switch_searchmode() {
      const vm = this;
      // await vm.init_category_data();
      vm.search.value = '';
      setTimeout(() => {
        vm.errors.clear('search');
        vm.$validator.reset();
        vm.$validator.attach();
      }, 100);
    },
    /* 取得主次分類列表 */
    async get_main_category() {
      const vm = this;
      await vm.init_category_data();
      if (vm.search.system === '') {
        return;
      }
      const response = await API_GET_PRODUCT_CATEGORY(vm.search.system);
      const res = response.data;
      // console.log('取得主次分類列表 成功', res);
      vm.category_data = res.data;
    },
    /* 篩選次分類列表 */
    async get_sub_category() {
      const vm = this;
      if (vm.search.main_category === '') {
        vm.search.sub_category = '';
        return;
      }
      vm.category_data.forEach((item) => {
        if (item.categoryCode === vm.search.main_category) {
          vm.subcategory_data = item.items;
        }
      });
    },
    /* 檢查搜尋模式 */
    async check_performance(systemCode, mode) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      if (vm.search.mode === '') {
        vm.$swal({
          icon: 'warning',
          title: '請選擇分析模式!',
          confirmButtonText: '關閉視窗',
        });
        await vm.$store.dispatch('page_load', false);
        return;
      }
      switch (mode) {
        case 'order':
          await vm.get_performance('order');
          break;
        case 'orderPerDay':
          await vm.get_performance('orderPerDay');
          break;
        case 'category':
          await vm.get_performance('category');
          break;
        case 'brand':
          await vm.get_performance('brand');
          break;
        case 'group':
          await vm.get_performance('group');
          break;
        case 'keyword':
          await vm.get_performance('keyword');
          break;
        case 'salesEvent':
          await vm.get_performance('salesEvent');
          break;
        case 'coupon':
          await vm.get_performance('coupon');
          break;
        default:
          break;
      }
    },
    /* 取得業績資料 */
    async get_performance(target) {
      const vm = this;
      let data = {};
      let validateResult;
      await vm.init_performance_data();
      await vm.init_pagination();
      vm.sort_amount_status = true;
      vm.sort_quantity_status = true;
      switch (target) {
        case 'order':
          // console.log('order');
          validateResult = await vm.$validator.validateAll('search');
          if (validateResult) {
            data = {
              systemCode: vm.search.system,
              dateStart: vm.search.dateStart,
              dateEnd: vm.search.dateEnd,
            };
            const response = await API_GET_PERFORMANCE_ORDER(data);
            const res = response.data;
            if (res.code === '0000') {
              vm.performance_order = res.data;
            }
            await vm.$store.dispatch('page_load', false);
          } else {
            vm.$swal({
              icon: 'warning',
              title: '請確認欄位!',
              confirmButtonText: '關閉視窗',
            });
            await vm.$store.dispatch('page_load', false);
          }
          break;
        case 'orderPerDay':
          // console.log('orderPerDay');
          validateResult = await vm.$validator.validateAll('search');
          if (validateResult) {
            data = {
              systemCode: vm.search.system,
              dateStart: vm.search.dateStart,
              dateEnd: vm.search.dateEnd,
            };
            const response = await API_GET_PERFORMANCE_ORDERPERDAY(data);
            const res = response.data;
            if (res.code === '0000') {
              vm.performance_orderPerDay = res.data;
            }
            await vm.$store.dispatch('page_load', false);
          } else {
            vm.$swal({
              icon: 'warning',
              title: '請確認欄位!',
              confirmButtonText: '關閉視窗',
            });
            await vm.$store.dispatch('page_load', false);
          }
          break;
        case 'category':
          // console.log('category');
          validateResult = await vm.$validator.validateAll('search');
          if (validateResult) {
            data = {
              systemCode: vm.search.system,
              dateStart: vm.search.dateStart,
              dateEnd: vm.search.dateEnd,
              categoryCode: vm.search.main_category,
              categorySubCode: vm.search.sub_category,
              optionGroupDate: vm.search.optionGroupDate,
            };
            const response = await API_GET_PERFORMANCE_CATEGORY(data);
            const res = response.data;
            if (res.code === '0000') {
              vm.performance_default = res.data;
            }
            await vm.$store.dispatch('page_load', false);
          } else {
            vm.$swal({
              icon: 'warning',
              title: '請確認欄位!',
              confirmButtonText: '關閉視窗',
            });
            await vm.$store.dispatch('page_load', false);
          }
          break;
        case 'brand':
          // console.log('brand');
          validateResult = await vm.$validator.validateAll('search');
          if (validateResult) {
            data = {
              systemCode: vm.search.system,
              dateStart: vm.search.dateStart,
              dateEnd: vm.search.dateEnd,
              Value: vm.search.value,
            };
            const response = await API_GET_PERFORMANCE_BRAND(data);
            const res = response.data;
            if (res.code === '0000') {
              vm.performance_default = res.data;
            }
            await vm.$store.dispatch('page_load', false);
          } else {
            vm.$swal({
              icon: 'warning',
              title: '請確認欄位!',
              confirmButtonText: '關閉視窗',
            });
            await vm.$store.dispatch('page_load', false);
          }
          break;
        case 'group':
          // console.log('group');
          validateResult = await vm.$validator.validateAll('search');
          if (validateResult) {
            data = {
              systemCode: vm.search.system,
              dateStart: vm.search.dateStart,
              dateEnd: vm.search.dateEnd,
              Value: vm.search.value,
            };
            const response = await API_GET_PERFORMANCE_GROUP(data);
            const res = response.data;
            if (res.code === '0000') {
              vm.performance_default = res.data;
            }
            await vm.$store.dispatch('page_load', false);
          } else {
            vm.$swal({
              icon: 'warning',
              title: '請確認欄位!',
              confirmButtonText: '關閉視窗',
            });
            await vm.$store.dispatch('page_load', false);
          }
          break;
        case 'keyword':
          // console.log('keyword');
          validateResult = await vm.$validator.validateAll('search');
          if (validateResult) {
            data = {
              systemCode: vm.search.system,
              dateStart: vm.search.dateStart,
              dateEnd: vm.search.dateEnd,
              Value: vm.search.value,
            };
            const response = await API_GET_PERFORMANCE_KEYWORD(data);
            const res = response.data;
            if (res.code === '0000') {
              vm.performance_default = res.data;
            }
            await vm.$store.dispatch('page_load', false);
          } else {
            vm.$swal({
              icon: 'warning',
              title: '請確認欄位!',
              confirmButtonText: '關閉視窗',
            });
            await vm.$store.dispatch('page_load', false);
          }
          break;
        case 'salesEvent':
          // console.log('salesEvent');
          validateResult = await vm.$validator.validateAll('search');
          if (validateResult) {
            data = {
              systemCode: vm.search.system,
              Value: vm.search.value,
            };
            const response = await API_GET_PERFORMANCE_SALESEVENT(data);
            const res = response.data;
            if (res.code === '0000') {
              vm.performance_event = res.data;
            }
            await vm.$store.dispatch('page_load', false);
          } else {
            vm.$swal({
              icon: 'warning',
              title: '請確認欄位!',
              confirmButtonText: '關閉視窗',
            });
            await vm.$store.dispatch('page_load', false);
          }
          break;
        case 'coupon':
          // console.log('coupon');
          validateResult = await vm.$validator.validateAll('search');
          if (validateResult) {
            data = {
              systemCode: vm.search.system,
              Value: vm.search.value,
            };
            const response = await API_GET_PERFORMANCE_COUPON(data);
            const res = response.data;
            if (res.code === '0000') {
              vm.performance_coupon = res.data;
            }
            await vm.$store.dispatch('page_load', false);
          } else {
            vm.$swal({
              icon: 'warning',
              title: '請確認欄位!',
              confirmButtonText: '關閉視窗',
            });
            await vm.$store.dispatch('page_load', false);
          }
          break;
        default:
          break;
      }
    },
    /* 排序 */
    async sort_data(target, sortType) {
      const vm = this;
      target.items.sort(vm.compare(sortType));
    },
    compare(type) {
      const vm = this;
      let value;
      switch (type) {
        case 'amount':
          vm.sort_quantity_status = true;
          if (!vm.sort_amount_status) {
            vm.sort_amount_status = !vm.sort_amount_status;
            value = (a, b) => {
              const val1 = a[type];
              const val2 = b[type];
              return val1 - val2;
            };
          } else {
            vm.sort_amount_status = !vm.sort_amount_status;
            value = (a, b) => {
              const val1 = a[type];
              const val2 = b[type];
              return val2 - val1;
            };
          }
          break;
        case 'quantity':
          vm.sort_amount_status = true;
          if (!vm.sort_quantity_status) {
            vm.sort_quantity_status = !vm.sort_quantity_status;
            value = (a, b) => {
              const val1 = a[type];
              const val2 = b[type];
              return val1 - val2;
            };
          } else {
            vm.sort_quantity_status = !vm.sort_quantity_status;
            value = (a, b) => {
              const val1 = a[type];
              const val2 = b[type];
              return val2 - val1;
            };
          }
          break;
        case 'counter':
          vm.sort_amount_status = true;
          if (!vm.sort_quantity_status) {
            vm.sort_quantity_status = !vm.sort_quantity_status;
            value = (a, b) => {
              const val1 = a[type];
              const val2 = b[type];
              return val1 - val2;
            };
          } else {
            vm.sort_quantity_status = !vm.sort_quantity_status;
            value = (a, b) => {
              const val1 = a[type];
              const val2 = b[type];
              return val2 - val1;
            };
          }
          break;
        default:
          break;
      }
      return value;
    },
  },
};
</script>
